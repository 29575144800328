// React
import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// Material UI
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import Grid from "@material-ui/core/Grid";
import { Link as MaterialLink } from "@material-ui/core";
import { Box, Typography } from "@material-ui/core";

// Core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// Local
import { COLORS } from "../Constants";

import ActionButton from "../components/atoms/ActionButton";
import MintButton from "../components/atoms/MintButton";
import GoBackArrow from "../components/atoms/GoBackArrow";
import Loading from "../components/sections/Loading";

import MintModal from "../components/sections/MintModal";
import ProductDeleteModal from "../components/sections/ProductDeleteModal";
import SellProductModal from "../components/sections/SellProductModal";
import TransferModal from "../components/sections/TransferModal";

import useGetUserProfile from "../hooks/useGetUserProfile";
import useGetProduct from "../hooks/useGetProduct";
import useGetMintRequest from "../hooks/useGetMintRequest";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: COLORS.enefete_blue,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const Product = () => {
    const history = useHistory();
    const params = useParams();
    const [profile, getProfile] = useGetUserProfile();
    const [product, getProduct] = useGetProduct();
    const [mintRequest, getMintRequest] = useGetMintRequest();

    /* MODALS */
    // Mint
    const [openModal, setOpenModal] = React.useState();

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const onModalActionSuccess = () => {
        handleCloseModal();
        getProfile();
        getProduct(params.id);
        getMintRequest(params.id);
    };

    // Delete
    const [
        openProductDeleteModal,
        setOpenProductDeleteModal,
    ] = React.useState();

    const handleOpenProductDeleteModal = () => {
        setOpenProductDeleteModal(true);
    };

    const handleCloseProductDeleteModal = () => {
        setOpenProductDeleteModal(false);
    };

    const onProductDeleleteModalSuccess = () => {
        history.push("/");
    };

    // Sell Modal
    const [openSellProductModal, setOpenSellProductModal] = React.useState();

    const handleOpenSellProductModal = () => {
        setOpenSellProductModal(true);
    };

    const handleCloseSellProductModal = () => {
        setOpenSellProductModal(false);
    };

    const onSellProductModalSucess = () => {
        getProduct(params.id);
    };

    // Transfer Modal
    const [openTransferModal, setOpenTransferModal] = React.useState();

    const handleOpenTransferModal = () => {
        setOpenTransferModal(true);
    };

    const handleCloseTransferModal = () => {
        setOpenTransferModal(false);
    };

    const onTransferModalSuccess = () => {
        getProduct(params.id);
    };

    /* Menu */
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const validateTokenExists = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            history.push("/sign-in");
        }
    };

    React.useEffect(() => {
        validateTokenExists();
        getProfile();
        getProduct(params.id);
        getMintRequest(params.id);
    }, []);

    if (!profile || !product) {
        return <Loading />;
    }

    const renderMintButton = () => {
        if (product.nft_url != null) return "";

        if (!profile.is_phone_verified) return "";
        if (!mintRequest)
            return (
                <MintButton
                    className="float-right"
                    onClick={(e) => {
                        e.preventDefault();
                        handleOpenModal();
                    }}
                >
                    Mint
                </MintButton>
            );

        if (mintRequest.status === "Pending")
            return (
                <Col sm="12">
                    <h5 className="float-right">
                        The minting is in process... 🚀
                    </h5>
                </Col>
            );

        return (
            <Col sm="12">
                <h5 className="float-right">We can't process this right now</h5>
            </Col>
        );
    };

    const renderMenu = () => {
        const buttons = [];
        if (product.nft_url) {
            if (product.is_managed) {
                buttons.push(
                    <ActionButton
                        className="float-right"
                        variant="contained"
                        color="primary"
                        onClick={handleOpenTransferModal}
                        style={{ marginLeft: 5 }}
                    >
                        Transfer
                    </ActionButton>
                );
            }
            if (product.for_sale_status === null)
                buttons.push(
                    <ActionButton
                        className="float-right"
                        variant="contained"
                        color="primary"
                        onClick={handleOpenSellProductModal}
                        style={{ marginLeft: 5 }}
                    >
                        Set for sale
                    </ActionButton>
                );

            return buttons.map((component, index) => (
                <React.Fragment key={index}>{component}</React.Fragment>
            ));
        }

        if (mintRequest) return "";
        return (
            <>
                <ActionButton
                    className="float-right"
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                    style={{ marginLeft: 5 }}
                >
                    &#9660; Menu
                </ActionButton>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <StyledMenuItem
                        onClick={() => {
                            history.push(`/products/${params.id}/edit/`);
                        }}
                    >
                        <ListItemIcon>
                            <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Edit" />
                    </StyledMenuItem>
                    <StyledMenuItem
                        onClick={() => {
                            handleClose();
                            handleOpenProductDeleteModal();
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Delete" />
                    </StyledMenuItem>
                </StyledMenu>
            </>
        );
    };

    const renderCollection = (collection) => {
        const collection_url = `/collections/${collection.id}`;
        return (
            <>
                <Grid container justify="center">
                    <img
                        alt={collection.name}
                        src={collection.image}
                        style={{
                            width: 100,
                            height: 100,
                            borderRadius: 50,
                            marginBottom: 10,
                        }}
                    />
                </Grid>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                >
                    <Typography component="h5" variant="h5">
                        Part of the{" "}
                        <Link to={collection_url}> {collection.name}</Link>{" "}
                        collection
                    </Typography>
                </Grid>
            </>
        );
    };

    const renderForSaleSection = (product) => {
        if (product.for_sale_status === null) return "";
        if (product.for_sale_status === "Pending") {
            return (
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Brightness1Icon
                        style={{
                            color: COLORS.enefete_green,
                            marginRight: 5,
                        }}
                    />

                    <Typography
                        color="textSecondary"
                        variant="h6"
                        style={{ marginRight: 5 }}
                    >
                        We're setting up this item for sale ($
                        {product.for_sale_price} USD)
                    </Typography>
                </Grid>
            );
        }

        if (product.for_sale_status === "For sale") {
            const marketplaces = [];
            let counter = 0;

            try {
                for (let marketplace in product.for_sale_marketplaces) {
                    marketplaces.push(
                        <MaterialLink
                            href={product.for_sale_marketplaces[marketplace]}
                            target="_blank"
                            rel="noopener"
                        >
                            {counter > 0 ? ", " : ""}
                            {marketplace}
                        </MaterialLink>
                    );
                    counter++;
                }
            } catch (e) {
                console.log("Couldn't render marketplaces");
            }

            return (
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Brightness1Icon
                        style={{
                            color: COLORS.enefete_green,
                            marginRight: 5,
                        }}
                    />

                    <Typography variant="h6" style={{ marginRight: 5 }}>
                        This item is up for sale. ({marketplaces})
                    </Typography>
                </Grid>
            );
        }
    };

    const renderTransferMessage = () => {
        let message = "";
        if (!product.is_managed) {
            message = "We don't manage this collectible";
            if (!product.transferred) {
                message = "We're transferring this item";
            }
        }

        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <Typography
                    variant="h6"
                    style={{ marginRight: 5 }}
                    color="textSecondary"
                >
                    {message}
                </Typography>
            </Grid>
        );
    };

    return (
        <>
            <MintModal
                product_id={product.id}
                profile={profile}
                open={openModal}
                handleClose={handleCloseModal}
                onSuccess={onModalActionSuccess}
            />
            <ProductDeleteModal
                product={product}
                open={openProductDeleteModal}
                handleClose={handleCloseProductDeleteModal}
                onSuccess={onProductDeleleteModalSuccess}
            />
            <SellProductModal
                product={product}
                open={openSellProductModal}
                handleClose={handleCloseSellProductModal}
                onSuccess={onSellProductModalSucess}
            />
            <TransferModal
                product_id={product.id}
                profile={profile}
                open={openTransferModal}
                handleClose={handleCloseTransferModal}
                onSuccess={onTransferModalSuccess}
            />
            <DemoNavbar history={history} />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                <Row></Row>
                                {!profile.is_phone_verified && (
                                    <Row
                                        className="justify-content-center"
                                        style={{ marginTop: 20 }}
                                    >
                                        <Col sm="12">
                                            <Alert severity="info">
                                                To enable the Minting of tokens
                                                you need to{" "}
                                                <Link to="/profile">
                                                    add a phone number
                                                </Link>
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                <Row
                                    className="justify-content-center"
                                    style={{ marginTop: 20 }}
                                >
                                    <Col xs="6">
                                        <GoBackArrow />
                                    </Col>
                                    <Col xs="6">
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="center"
                                        >
                                            {renderMintButton()}
                                            {renderMenu()}
                                        </Grid>
                                    </Col>
                                </Row>

                                <div className="text-center mt-2">
                                    <h3>{product.name}</h3>
                                </div>

                                {renderTransferMessage()}

                                {product.nft_url != null && (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <Brightness1Icon
                                            style={{
                                                color: COLORS.enefete_yellow,
                                                marginRight: 5,
                                            }}
                                        />

                                        <Typography
                                            variant="h6"
                                            style={{ marginRight: 5 }}
                                        >
                                            Minted.{" "}
                                            <MaterialLink
                                                href={product.nft_url}
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                See transaction
                                            </MaterialLink>
                                        </Typography>
                                    </Grid>
                                )}

                                {renderForSaleSection(product)}

                                <Box m={2} />

                                {/* Image */}
                                <Row>
                                    <Col sm="12">
                                        <img src={product.image} width="100%" />
                                    </Col>
                                </Row>
                                {/* Ends Image */}

                                <Box m={5} />
                                {/* Collection*/}
                                {product.collection &&
                                    renderCollection(product.collection)}
                                {/* Ends Collection*/}

                                <div className="text-center mt-5">
                                    <h4>{product.description}</h4>
                                </div>

                                {product.properties.length > 0 && (
                                    <div className="text-center mt-5">
                                        <Typography
                                            component="h5"
                                            variant="h5"
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Properties
                                        </Typography>
                                        {product.properties.map((property) => (
                                            <>
                                                <Grid
                                                    item
                                                    direction="row"
                                                    justify="center"
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        display="inline"
                                                        style={{
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {property.name}:
                                                    </Typography>
                                                    <Typography
                                                        variant="h6"
                                                        display="inline"
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        {property.value}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <Box m={5} />
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </>
    );
};

export default Product;
