// React
import React from "react";
import { useHistory, Link } from "react-router-dom";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// Material UI
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhotoIcon from "@material-ui/icons/Photo";
import Collections from "@material-ui/icons/Collections";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// Local
import ActionButton from "../components/atoms/ActionButton";
import ProductCard from "../components/atoms/ProductCard";
import CollectionCard from "../components/atoms/CollectionCard";
import Loading from "../components/sections/Loading";
import useGetUserProfile from "../hooks/useGetUserProfile";
import useGetUserProducts from "../hooks/useGetUserProducts";
import useGetUserCollections from "hooks/useGetUserCollections";
import { Mixpanel } from "../providers/Mixpanel";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 500,
    },
    indicator: {
        backgroundColor: "#1890ff",
    },
});

const Profile = () => {
    const classes = useStyles();
    const history = useHistory();
    const [userProducts, getUserProducts] = useGetUserProducts();
    const [userCollections, getUserCollections] = useGetUserCollections();
    const [profile, getProfile] = useGetUserProfile();
    const [tab, setTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const validateTokenExists = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            history.push("/sign-in");
        }
    };

    React.useEffect(() => {
        validateTokenExists();
        getProfile();
        getUserProducts();
        getUserCollections();
    }, []);

    const renderProducts = (userProducts) => {
        return (
            <div className="album py-5">
                <Container>
                    <Row>
                        {userProducts.map((item) => (
                            <ProductCard
                                product={item}
                                isOwned={item.owner.id === profile.id}
                                key={item.id}
                            />
                        ))}
                    </Row>
                </Container>
            </div>
        );
    };

    const renderCollections = (userCollections) => {
        return (
            <div className="album py-5">
                <Container>
                    <Row>
                        {userCollections.map((item) => (
                            <CollectionCard collection={item} />
                        ))}
                    </Row>
                </Container>
            </div>
        );
    };

    if (!profile) {
        return <Loading />;
    }

    const renderSection = () => {
        if (tab === 0)
            return (
                <div className="mt-3 py-2 text-center">
                    {userProducts.length === 0 && (
                        <h3>You have no Singles. Start creating.</h3>
                    )}
                    {userProducts.length > 0 && renderProducts(userProducts)}
                </div>
            );
        return (
            <div className=" py-2 text-center">
                {userCollections.length === 0 && (
                    <h5>You have no collections</h5>
                )}
                {userCollections.length > 0 &&
                    renderCollections(userCollections)}
            </div>
        );
    };

    return (
        <>
            <DemoNavbar history={history} />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                {!profile.is_phone_verified && (
                                    <Row
                                        className="justify-content-center"
                                        style={{ marginTop: 20 }}
                                    >
                                        <Col sm="12">
                                            <Alert severity="info">
                                                To enable the Minting of tokens
                                                you need to{" "}
                                                <Link to="/profile">
                                                    add a phone number
                                                </Link>
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}

                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        {/* <div className="card-profile-image">
                                            <a
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <img
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src="https://enefete.s3-us-west-2.amazonaws.com/enefete-side.svg"
                                                />
                                            </a>
                                        </div> */}
                                    </Col>
                                    <Col
                                        className="order-lg-3 text-lg-right align-self-lg-center"
                                        lg="4"
                                    ></Col>
                                    <Col className="order-lg-1" lg="4">
                                        <div className="card-profile-stats d-flex justify-content-center">
                                            <div>
                                                <span className="heading">
                                                    {userProducts.length}
                                                </span>
                                                <span className="description">
                                                    Collectible
                                                    {userProducts.length === 1
                                                        ? ""
                                                        : "s"}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="heading">
                                                    {userCollections.length}
                                                </span>
                                                <span className="description">
                                                    Collection
                                                    {userCollections.length ===
                                                    1
                                                        ? ""
                                                        : "s"}
                                                </span>
                                            </div>
                                            <div>
                                                <span className="heading">
                                                    {profile.credits}
                                                </span>
                                                <span className="description">
                                                    Credits
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center mt-3">
                                    <h3>{profile.username}</h3>

                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <ActionButton
                                            onClick={(e) => {
                                                e.preventDefault();
                                                history.push("/upload-form");
                                            }}
                                            style={{ marginRight: 10 }}
                                        >
                                            + NFT
                                        </ActionButton>
                                        <ActionButton
                                            onClick={(e) => {
                                                e.preventDefault();
                                                history.push(
                                                    "/collection-form"
                                                );
                                            }}
                                        >
                                            + Collection
                                        </ActionButton>
                                    </Grid>
                                </div>

                                <div className="mt-5 py-2 border-top text-center"></div>
                                <Box m={3} />
                                <Grid container justify="center">
                                    <Paper square className={classes.root}>
                                        <Tabs
                                            value={tab}
                                            onChange={handleTabChange}
                                            variant="fullWidth"
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="icon label tabs example"
                                        >
                                            <Tab
                                                icon={<PhotoIcon />}
                                                label="Collectibles"
                                            />
                                            <Tab
                                                icon={<Collections />}
                                                label="Collections"
                                            />
                                            {/* <Tab
                                                icon={<PersonPinIcon />}
                                                label="NEARBY"
                                            /> */}
                                        </Tabs>
                                    </Paper>
                                </Grid>

                                {renderSection()}
                            </div>
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </>
    );
};

export default Profile;
