import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    UncontrolledTooltip,
} from "reactstrap";

export default function DemoNavbar() {
    const [collapseClasses, setCollapseClasses] = React.useState("");
    const [token, setToken] = React.useState(null);

    const getToken = () => {
        const token = localStorage.getItem("token");

        if (token) {
            setToken(token);
        }
    };

    React.useEffect(() => {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();
        getToken();
    });

    const onExiting = () => {
        setCollapseClasses("collapsing-out");
    };

    const onExited = () => {
        setCollapseClasses("");
    };

    return (
        <>
            <header className="header-global">
                <Navbar
                    className="navbar-main navbar-transparent navbar-light headroom"
                    expand="lg"
                    id="navbar-main"
                >
                    <Container>
                        <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                            {/* <img
                                    alt="..."
                                    src={require("assets/img/brand/argon-react-white.png")}
                                /> */}
                            <img
                                src="https://enefete.s3-us-west-2.amazonaws.com/enefete-side.svg"
                                height={50}
                                width={150}
                                alt="enefete logo"
                                style={{
                                    height: 100,
                                }}
                            />
                        </NavbarBrand>
                        <button className="navbar-toggler" id="navbar_global">
                            <i
                                className="fa fa-bars"
                                style={{ fontSize: "2rem" }}
                            />
                        </button>
                        <UncontrolledCollapse
                            toggler="#navbar_global"
                            navbar
                            className={collapseClasses}
                            onExiting={onExiting}
                            onExited={onExited}
                        >
                            <div className="navbar-collapse-header">
                                <Row>
                                    <Col className="collapse-brand" xs="6">
                                        <Link to="/">
                                            <img
                                                src="https://enefete.s3-us-west-2.amazonaws.com/enefete-single.svg"
                                                alt="enefete logo"
                                                style={{ borderRadius: 50 }}
                                            />
                                        </Link>
                                    </Col>
                                    <Col className="collapse-close" xs="6">
                                        <button
                                            className="navbar-toggler"
                                            id="navbar_global"
                                        >
                                            <span />
                                            <span />
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            {/* <Nav
                                    className="navbar-nav-hover align-items-lg-center"
                                    navbar
                                >
                                    <UncontrolledDropdown nav>
                                        <DropdownToggle nav>
                                            <i className="ni ni-ui-04 d-lg-none mr-1" />
                                            <span className="nav-link-inner--text">
                                                Components
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-xl">
                                            <div className="dropdown-menu-inner">
                                                <Media
                                                    className="d-flex align-items-center"
                                                    href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/overview?ref=adsr-navbar"
                                                    target="_blank"
                                                >
                                                    <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                                        <i className="ni ni-spaceship" />
                                                    </div>
                                                    <Media
                                                        body
                                                        className="ml-3"
                                                    >
                                                        <h6 className="heading text-primary mb-md-1">
                                                            Getting started
                                                        </h6>
                                                        <p className="description d-none d-md-inline-block mb-0">
                                                            Learn how to use
                                                            Argon compiling
                                                            Scss, change brand
                                                            colors and more.
                                                        </p>
                                                    </Media>
                                                </Media>
                                                <Media
                                                    className="d-flex align-items-center"
                                                    href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/colors?ref=adsr-navbar"
                                                    target="_blank"
                                                >
                                                    <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                                                        <i className="ni ni-palette" />
                                                    </div>
                                                    <Media
                                                        body
                                                        className="ml-3"
                                                    >
                                                        <h6 className="heading text-primary mb-md-1">
                                                            Foundation
                                                        </h6>
                                                        <p className="description d-none d-md-inline-block mb-0">
                                                            Learn more about
                                                            colors, typography,
                                                            icons and the grid
                                                            system we used for
                                                            Argon.
                                                        </p>
                                                    </Media>
                                                </Media>
                                                <Media
                                                    className="d-flex align-items-center"
                                                    href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alert?ref=adsr-navbar"
                                                    target="_blank"
                                                >
                                                    <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                                                        <i className="ni ni-ui-04" />
                                                    </div>
                                                    <Media
                                                        body
                                                        className="ml-3"
                                                    >
                                                        <h5 className="heading text-warning mb-md-1">
                                                            Components
                                                        </h5>
                                                        <p className="description d-none d-md-inline-block mb-0">
                                                            Browse our 50
                                                            beautiful
                                                            handcrafted
                                                            components offered
                                                            in the Free version.
                                                        </p>
                                                    </Media>
                                                </Media>
                                            </div>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown nav>
                                        <DropdownToggle nav>
                                            <i className="ni ni-collection d-lg-none mr-1" />
                                            <span className="nav-link-inner--text">
                                                Examples
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                to="/landing-page"
                                                tag={Link}
                                            >
                                                Landing
                                            </DropdownItem>
                                            <DropdownItem
                                                to="/profile-page"
                                                tag={Link}
                                            >
                                                Profile
                                            </DropdownItem>
                                            <DropdownItem
                                                to="/login-page"
                                                tag={Link}
                                            >
                                                Login
                                            </DropdownItem>
                                            <DropdownItem
                                                to="/register-page"
                                                tag={Link}
                                            >
                                                Register
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav> */}
                            <Nav
                                className="align-items-lg-center ml-lg-auto"
                                navbar
                            >
                                {/* <NavItem>
                                        <NavLink
                                            className="nav-link-icon"
                                            href="https://www.instagram.com/creativetimofficial"
                                            id="tooltip356693867"
                                            target="_blank"
                                        >
                                            <i className="fa fa-instagram" />
                                            <span className="nav-link-inner--text d-lg-none ml-2">
                                                Instagram
                                            </span>
                                        </NavLink>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip356693867"
                                        >
                                            Follow us on Instagram
                                        </UncontrolledTooltip>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className="nav-link-icon"
                                            href="https://twitter.com/creativetim"
                                            id="tooltip184698705"
                                            target="_blank"
                                        >
                                            <i className="fa fa-twitter-square" />
                                            <span className="nav-link-inner--text d-lg-none ml-2">
                                                Twitter
                                            </span>
                                        </NavLink>
                                        <UncontrolledTooltip
                                            delay={0}
                                            target="tooltip184698705"
                                        >
                                            Follow us on Twitter
                                        </UncontrolledTooltip>
                                    
                                    </NavItem> */}
                                {!token && (
                                    <NavItem>
                                        <NavLink
                                            className="nav-link-icon"
                                            id="tooltip-home"
                                            href="/sign-in"
                                        >
                                            Sign In
                                        </NavLink>
                                    </NavItem>
                                )}
                                {token && (
                                    <>
                                        <NavItem>
                                            <NavLink
                                                className="nav-link-icon"
                                                id="tooltip-home"
                                                href="/"
                                            >
                                                Home
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className="nav-link-icon"
                                                id="tooltip-profile"
                                                href="/profile"
                                            >
                                                Profile
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className="nav-link-icon"
                                                id="tooltip-profile"
                                                href="/pricing"
                                            >
                                                Plans
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className="nav-link-icon"
                                                id="tooltip1"
                                                href="/credits"
                                            >
                                                Credits
                                            </NavLink>
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip1"
                                            >
                                                Get more credits
                                            </UncontrolledTooltip>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className="nav-link-icon"
                                                id="tooltip112445449"
                                                href="/sign-out"
                                            >
                                                <i className="fa fa-sign-out" />
                                                <span className="nav-link-inner--text d-lg-none ml-2">
                                                    Sign Out
                                                </span>
                                            </NavLink>
                                            <UncontrolledTooltip
                                                delay={0}
                                                target="tooltip112445449"
                                            >
                                                Sign Out
                                            </UncontrolledTooltip>
                                        </NavItem>
                                    </>
                                )}

                                {/* <NavItem className="d-none d-lg-block ml-lg-4">
                                        <Button
                                            className="btn-neutral btn-icon"
                                            color="default"
                                            onClick={this.logOut}
                                        >
                                            <span className="nav-link-inner--text ml-1">
                                                Sign Out
                                            </span>
                                        </Button>
                                    </NavItem> */}
                            </Nav>
                        </UncontrolledCollapse>
                    </Container>
                </Navbar>
            </header>
        </>
    );
}
