// React
import React from "react";

// Local
import enefete from "../api/enefete";

export default function useGetUserCollections() {
    const [collections, setCollections] = React.useState([]);

    const getCollections = async () => {
        try {
            const response = await enefete.get(`/v1/collections/`, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });
            if (response.status === 200) {
                setCollections(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    return [collections, getCollections];
}
