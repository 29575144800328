// React
import React from "react";
import { useHistory, useParams, Link } from "react-router-dom";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// Material UI
import Grid from "@material-ui/core/Grid";
import { Box, Typography } from "@material-ui/core";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

// Core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// Local
import GoBackArrow from "../components/atoms/GoBackArrow";
import Loading from "../components/sections/Loading";

import useGetProduct from "../hooks/useGetProduct";

const Art = () => {
    const history = useHistory();
    const params = useParams();
    const [product, getProduct] = useGetProduct();

    React.useEffect(() => {
        getProduct(params.id);
    }, [getProduct, params.id]);

    if (!product) {
        return <Loading />;
    }

    const renderCollection = (collection) => {
        const collection_url = `/collections/${collection.id}`;
        return (
            <>
                <Grid container justify="center">
                    <img
                        alt={collection.name}
                        src={collection.image}
                        style={{
                            width: 100,
                            height: 100,
                            borderRadius: 50,
                            marginBottom: 10,
                        }}
                    />
                </Grid>
                <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                >
                    <Typography component="h5" variant="h5">
                        Part of the{" "}
                        <Link to={collection_url}> {collection.name}</Link>{" "}
                        collection
                    </Typography>
                </Grid>
            </>
        );
    };

    return (
        <>
            <DemoNavbar history={history} />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                <Row></Row>

                                <Row
                                    className="justify-content-center"
                                    style={{ marginTop: 20 }}
                                >
                                    <Col xs="6">
                                        <GoBackArrow />
                                    </Col>
                                    <Col xs="6">
                                        <Grid
                                            container
                                            direction="row"
                                            justify="flex-end"
                                            alignItems="center"
                                        ></Grid>
                                    </Col>
                                </Row>

                                <div className="text-center mt-2">
                                    <h3>{product.name}</h3>
                                </div>

                                <Box m={2} />

                                {/* Image */}
                                <Row>
                                    <Col sm="12">
                                        <img
                                            src={product.image}
                                            width="100%"
                                            alt={product.name}
                                        />
                                    </Col>
                                </Row>
                                {/* Ends Image */}

                                <Box m={5} />
                                {/* Collection*/}
                                {product.collection &&
                                    renderCollection(product.collection)}
                                {/* Ends Collection*/}
                                <Box m={5} />
                                <Grid container spacing={6}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            component="h5"
                                            variant="h5"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            Description
                                        </Typography>
                                        <Typography
                                            component="h6"
                                            variant="h6"
                                            align="justify"
                                        >
                                            {product.description}
                                        </Typography>
                                        <Box m={4} />
                                        <Typography
                                            component="h5"
                                            variant="h5"
                                            style={{ fontWeight: "bold" }}
                                        >
                                            Edition
                                        </Typography>
                                        <Typography component="h6" variant="h6">
                                            1 of 1
                                        </Typography>
                                        {product.properties.length > 0 && (
                                            <div className="mt-4">
                                                <Typography
                                                    component="h5"
                                                    variant="h5"
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Properties
                                                </Typography>
                                                {product.properties.map(
                                                    (property) => (
                                                        <>
                                                            <Grid
                                                                item
                                                                direction="row"
                                                            >
                                                                <Typography
                                                                    component="h6"
                                                                    variant="h6"
                                                                    display="inline"
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        property.name
                                                                    }
                                                                    :
                                                                </Typography>
                                                                <Typography
                                                                    component="h6"
                                                                    variant="h6"
                                                                    display="inline"
                                                                    style={{
                                                                        marginLeft: 10,
                                                                    }}
                                                                >
                                                                    {
                                                                        property.value
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                        >
                                            {product.creator.photo && (
                                                <img
                                                    src={product.creator.photo}
                                                    style={{
                                                        height: 50,
                                                        widt: 50,
                                                        borderRadius: 50,
                                                    }}
                                                    alt={
                                                        product.creator.username
                                                    }
                                                />
                                            )}
                                            {!product.owner.photo && (
                                                <AccountBoxIcon />
                                            )}
                                            <Typography
                                                component="h6"
                                                variant="h6"
                                                style={{ marginLeft: 20 }}
                                            >
                                                Created by{" "}
                                                {product.creator.username}
                                            </Typography>
                                        </Grid>
                                        <Box m={3} />

                                        <Grid
                                            container
                                            direction="row"
                                            alignItems="center"
                                        >
                                            {product.creator.photo && (
                                                <img
                                                    src={product.creator.photo}
                                                    style={{
                                                        height: 50,
                                                        widt: 50,
                                                        borderRadius: 50,
                                                    }}
                                                    alt={
                                                        product.creator.username
                                                    }
                                                />
                                            )}
                                            {!product.owner.photo && (
                                                <AccountBoxIcon />
                                            )}
                                            <Typography
                                                component="h6"
                                                variant="h6"
                                                style={{ marginLeft: 20 }}
                                            >
                                                Owned by{" "}
                                                {product.creator.username}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <Box m={5} />
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </>
    );
};

export default Art;
