// React
import React from "react";

// Local
import enefete from "../api/enefete";

export default function useGetProduct() {
    const [product, setProduct] = React.useState();

    const getProduct = async (id) => {
        try {
            const response = await enefete.get(`/v1/products/${id}/`);
            if (response.status === 200) {
                setProduct(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    return [product, getProduct];
}
