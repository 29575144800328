// React
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

// Local
import enefete from "../api/enefete";

export default function SubscriptionSuccess() {
    const history = useHistory();
    let query = new URLSearchParams(useLocation().search);

    const getSubscriptionSuccess = async (session_id) => {
        try {
            const response = await enefete.get(
                "/v1/subscriptions/checkout-success?session_id=" + session_id,
                {
                    headers: {
                        Authorization: `Token ${localStorage.getItem("token")}`,
                    },
                }
            );

            if (response.status === 200) {
                history.push("/profile");
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    React.useEffect(() => {
        if (!query.get("session_id")) {
            history.push("/");
        }

        getSubscriptionSuccess(query.get("session_id"));
    }, []);
    return <h1>Validating...</h1>;
}
