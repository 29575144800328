// React
import React from "react";
import { useHistory, useParams } from "react-router-dom";

// Material UI

import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// Core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";

// Reactstrap
import { Card, Container } from "reactstrap";

// Third Party
import { useDropzone } from "react-dropzone";

// Local
import { COLORS } from "../Constants";
import ActionButton from "../components/atoms/ActionButton";
import enefete from "api/enefete";
import SimpleFooter from "./Footers/SimpleFooter";
import useGetCollection from "hooks/useGetCollection";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
};

const img = {
    display: "block",
    width: "auto",
    height: "100%",
};

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0 20px 0",
    borderWidth: 4,
    borderRadius: 4,
    borderColor: "#c0c0c0",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "20em",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const CREATE = "CREATE";
const EDIT = "EDIT";

const CollectionForm = () => {
    const [type, setType] = React.useState(CREATE);
    const params = useParams();
    if (params.id && type !== EDIT) {
        setType(EDIT);
    }
    const classes = useStyles();
    const history = useHistory();

    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [collection, getCollection] = useGetCollection();

    React.useEffect(() => {
        if (type === EDIT) {
            getCollection(params.id);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [type]);

    const [files, setFiles] = React.useState([]);
    const [fileError, setFileError] = React.useState("");
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: "image/*",
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            setFileError("");
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    React.useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt={file.name} />
            </div>
        </div>
    ));

    const uploadForm = async (formData) => {
        try {
            const response = await enefete.post("/v1/collections/", formData, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });
            if (response.status === 201) {
                history.push(`/collections/${response.data.id}`);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    const sendUpdate = async (formData) => {
        try {
            const response = await enefete.patch(
                `/v1/collections/${params.id}`,
                formData,
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status === 200) {
                history.push(`/collections/${params.id}`);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        let formError = false;

        if (!files.length) {
            formError = true;
            setFileError("Please upload a valid image");
        }

        if (formError) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        const formData = new FormData();
        formData.append("image", files[0]); // appending file
        formData.append("name", name);
        formData.append("description", description);

        uploadForm(formData);
    };

    const handleUpdateSubmit = (event) => {
        event.preventDefault();
        let formError = false;

        if (formError) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        const formData = new FormData();
        if (files.length) {
            formData.append("image", files[0]); // appending file
        }

        formData.append("name", name);
        formData.append("description", description);

        sendUpdate(formData);
    };

    const handleSubmit =
        type === CREATE ? handleCreateSubmit : handleUpdateSubmit;

    const updateName = (event) => {
        setName(event.target.value);
    };

    const updateDescription = (event) => {
        setDescription(event.target.value);
    };

    if (collection && !name) {
        setName(collection.name);
        setDescription(collection.description);
    }

    const renderTitle = () => {
        if (type === EDIT) return `Edit Collection`;
        return "Create Collection";
    };

    return (
        <>
            <DemoNavbar history={history} />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                <Container component="main" maxWidth="xs">
                                    <CssBaseline />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <ArrowBackIcon
                                                onClick={() => {
                                                    history.goBack();
                                                }}
                                                style={{
                                                    color: COLORS.enefete_blue,
                                                    fontSize: "2.5em",
                                                    marginTop: 20,
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className={classes.paper}>
                                        <Typography component="h1" variant="h5">
                                            {renderTitle()}
                                        </Typography>
                                        <Box m={2} />

                                        <form
                                            className={classes.form}
                                            onSubmit={handleSubmit}
                                        >
                                            {fileError && (
                                                <Typography
                                                    component="h1"
                                                    variant="h6"
                                                    color="error"
                                                >
                                                    {fileError}
                                                </Typography>
                                            )}
                                            {type === EDIT && (
                                                <>
                                                    <Typography
                                                        component="h3"
                                                        variant="h5"
                                                    >
                                                        Current image
                                                    </Typography>
                                                    <img
                                                        srcSet={
                                                            collection?.image
                                                        }
                                                        alt={collection?.name}
                                                        height={100}
                                                        width={100}
                                                    />
                                                    <Box mt={2} />
                                                </>
                                            )}
                                            <section>
                                                <div
                                                    {...getRootProps({ style })}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <p>
                                                        Drag 'n' drop some files
                                                        here, or click to select
                                                        files
                                                    </p>
                                                </div>
                                                <aside style={thumbsContainer}>
                                                    {thumbs}
                                                </aside>
                                            </section>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="name"
                                                label="Name"
                                                name="name"
                                                type="text"
                                                value={name}
                                                onChange={updateName}
                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="description"
                                                label="Description"
                                                name="description"
                                                type="text"
                                                multiline
                                                rows={4}
                                                value={description}
                                                onChange={updateDescription}
                                            />
                                            <ActionButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                            >
                                                Upload
                                            </ActionButton>
                                        </form>
                                    </div>
                                </Container>
                            </div>
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </>
    );
};

export default CollectionForm;
