// React
import React from "react";
import { useHistory } from "react-router-dom";

// Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

// Local
import ActionButton from "../components/atoms/ActionButton";
import enefete from "../api/enefete";

// function Copyright() {
//     return (
//         <Typography variant="body2" color="textSecondary" align="center">
//             {"Copyright © "}
//             <Link color="inherit" href="https://material-ui.com/">
//                 Your Website
//             </Link>{" "}
//             {new Date().getFullYear()}
//             {"."}
//         </Typography>
//     );
// }

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();
    const history = useHistory();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState();

    const signIn = async (email, password) => {
        try {
            const response = await enefete.post("v1/auth/token/login", {
                email,
                password,
            });
            if (response.status === 200) {
                localStorage.setItem("token", response.data.auth_token);
                history.push("/");
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
            setError("Invalid credentials");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        signIn(email, password);
    };

    const updateEmail = (event) => {
        setEmail(event.target.value);
        setError(null);
    };

    const updatePassword = (event) => {
        setPassword(event.target.value);
        setError(null);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img
                    width={100}
                    src="https://enefete.s3-us-west-2.amazonaws.com/enefete-single.svg"
                    alt="enefete logo"
                />
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {error && (
                    <Typography component="h1" variant="h6" color="error">
                        Invalid credentials.
                    </Typography>
                )}
                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={updateEmail}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={updatePassword}
                    />
                    {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                    <ActionButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                    </ActionButton>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/password-reset" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/sign-up" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {/* <Box mt={8}>
                <Copyright />
            </Box> */}
        </Container>
    );
}
