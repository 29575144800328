// React
import React from "react";

// reactstrap components
import { Card, Container } from "reactstrap";

// Material UI
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// Third Party
import { loadStripe } from "@stripe/stripe-js";

// Local
import enefete from "../api/enefete";

const stripePromise = loadStripe(
    "pk_test_51IVkSgLSb7rvTYwTZ1Ljt2YDtEvZpJ893P9nhXRGEh0r54M8fRXbiTo7sdlxbcf9mhB75RKl8UCmO0e8sA8A8x7g00u0thXA3W"
);

const useStyles = makeStyles((theme) => ({
    "@global": {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: "none",
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: "wrap",
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
    },
    cardPricing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

const tiers = [
    {
        title: "Hobbyst",
        price: "40",
        description: ["1 credit"],
        quantity: 1,
        buttonText: "Purchase",
        buttonVariant: "outlined",
    },
    {
        title: "Enthusiast",
        price: "180",
        description: ["5 credits (5% off)"],
        quantity: 5,
        buttonText: "Purchase",
        buttonVariant: "outlined",
    },
    {
        title: "Pro",
        price: "360",
        description: ["10 credits (10% off)"],
        quantity: 10,
        buttonText: "Purchase",
        buttonVariant: "outlined",
    },
];

export default function Credits() {
    const classes = useStyles();

    const handleClick = async (quantity) => {
        // Get Stripe.js instance
        const stripe = await stripePromise;

        // Call your backend to create the Checkout Session
        try {
            const response = await enefete.post(
                `/v1/credits/create-checkout-session/`,
                { quantity },
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );

            const { session } = response.data;

            // When the customer clicks on the button, redirect them to Checkout.
            const result = await stripe.redirectToCheckout({
                sessionId: session,
            });

            if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
            return;
        }
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <DemoNavbar />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                <Container
                                    maxWidth="sm"
                                    component="main"
                                    className={classes.heroContent}
                                >
                                    <Typography
                                        component="h1"
                                        variant="h2"
                                        align="center"
                                        color="textPrimary"
                                        gutterBottom
                                    >
                                        Credits
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        align="center"
                                        color="textSecondary"
                                        component="p"
                                    ></Typography>
                                </Container>
                                {/* End hero unit */}
                                <Container maxWidth="md" component="main">
                                    <Grid
                                        container
                                        spacing={5}
                                        alignItems="flex-end"
                                    >
                                        {tiers.map((tier) => (
                                            // Enterprise card is full width at sm breakpoint
                                            <Grid
                                                item
                                                key={tier.title}
                                                xs={12}
                                                sm={
                                                    tier.title === "Enterprise"
                                                        ? 12
                                                        : 6
                                                }
                                                md={4}
                                            >
                                                <Card>
                                                    <CardHeader
                                                        title={tier.title}
                                                        subheader={
                                                            tier.subheader
                                                        }
                                                        titleTypographyProps={{
                                                            align: "center",
                                                        }}
                                                        subheaderTypographyProps={{
                                                            align: "center",
                                                        }}
                                                        className={
                                                            classes.cardHeader
                                                        }
                                                    />
                                                    <CardContent>
                                                        <div
                                                            className={
                                                                classes.cardPricing
                                                            }
                                                        >
                                                            <Typography
                                                                component="h2"
                                                                variant="h3"
                                                                color="textPrimary"
                                                            >
                                                                ${tier.price}
                                                            </Typography>
                                                        </div>
                                                        <ul>
                                                            {tier.description.map(
                                                                (line) => (
                                                                    <Typography
                                                                        component="li"
                                                                        variant="subtitle1"
                                                                        align="center"
                                                                        key={
                                                                            line
                                                                        }
                                                                    >
                                                                        {line}
                                                                    </Typography>
                                                                )
                                                            )}
                                                        </ul>
                                                    </CardContent>
                                                    <CardActions>
                                                        <Button
                                                            fullWidth
                                                            variant={
                                                                tier.buttonVariant
                                                            }
                                                            color="primary"
                                                            onClick={() =>
                                                                handleClick(
                                                                    tier.quantity
                                                                )
                                                            }
                                                        >
                                                            {tier.buttonText}
                                                        </Button>
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Box m={5} />
                                </Container>
                            </div>
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </React.Fragment>
    );
}
