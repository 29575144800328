// React
import React from "react";
import { useHistory } from "react-router-dom";

// Material UI
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { COLORS } from "../../Constants";

export default function GoBackArrow() {
    const history = useHistory();
    return (
        <ArrowBackIcon
            onClick={() => {
                history.goBack();
            }}
            style={{
                color: COLORS.enefete_blue,
                fontSize: "2.5em",
                cursor: "pointer",
            }}
        />
    );
}
