// React
import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";

// Local
import { COLORS } from "../../Constants";
import CancelButton from "../atoms/CancelButton";
import enefete from "../../api/enefete";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    sendCode: {
        color: "#6774b7",
        cursor: "pointer",
        fontWeight: "bold",
        "&:hover": {
            color: "#414c88",
        },
    },
}));

const ProductDeleteModal = ({ open, handleClose, product, onSuccess }) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [productName, setProductName] = React.useState("");
    const [productNameError, setProductNameError] = React.useState("");

    const updateProductName = (event) => {
        setProductName(event.target.value);
        setProductNameError(false);
    };

    const sendDelete = async () => {
        try {
            const response = await enefete.delete(
                `/v1/products/${product.id}/delete/`,
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status === 204) {
                onSuccess();
            }
        } catch (e) {
            console.log(e);
            alert("We can't process this right now");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (productName !== product.name) {
            setProductNameError("The value is incorrect");
            return;
        }

        sendDelete();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container>
                    <Grid item xs="6">
                        <Typography variant="h4" component="h4">
                            Delete
                        </Typography>
                    </Grid>
                    <Grid item xs="6">
                        <CloseIcon
                            className="float-right"
                            onClick={handleClose}
                            style={{
                                cursor: "pointer",
                                color: COLORS.enefete_blue,
                                fontSize: "2.8em",
                            }}
                        />
                    </Grid>
                </Grid>
                <Box m={2} />
                <Grid container>
                    <Grid item xs="12">
                        <Alert severity="error">
                            This action can't be reversed.
                        </Alert>
                    </Grid>
                </Grid>
                <Box m={3} />
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography display="inline">
                                To confirm the deletion of this product please
                                insert the name{" "}
                                <Typography
                                    color="textSecondary"
                                    display="inline"
                                >
                                    {" "}
                                    ({product.name}){" "}
                                </Typography>
                                in the input below.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box m={3} />
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                error={Boolean(productNameError)}
                                variant="outlined"
                                label="Product name"
                                placeholder={product.name}
                                name="property_name"
                                helperText={
                                    productNameError ? productNameError : ""
                                }
                                fullWidth
                                required
                                value={productName}
                                onChange={updateProductName}
                            />
                        </Grid>
                    </Grid>
                    <Box m={2} />
                    <Grid container>
                        <Grid item xs={12}>
                            <CancelButton fullWidth type="submit">
                                Delete
                            </CancelButton>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
};

export default ProductDeleteModal;
