// React
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Components
import Art from "./Art";
import Collection from "./Collection";
import CollectionForm from "./CollectionForm";
import Credits from "./Credits";
import CreditsOrderSuccess from "./CreditsOrderSuccess";
import Home from "./Home";
import Index from "../views/Index";
import NotFoundPage from "./NotFoundPage";
import Pricing from "./Pricing";
import Product from "./Product";
import Profile from "./Profile";
import SignIn from "./SignIn";
import SignOut from "./SignOut";
import SignUp from "./SignUp";
import SubscriptionSuccess from "./SubscriptionSuccess";
import UploadForm from "./UploadForm";

// import Profile from "../views/examples/Profile.js";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/template" component={Index} />
                <Route exact path="/" component={Home} />
                <Route exact path="/sign-in" component={SignIn} />
                <Route exact path="/sign-up" component={SignUp} />
                <Route exact path="/sign-out" component={SignOut} />
                <Route exact path="/upload-form" component={UploadForm} />
                <Route
                    exact
                    path="/collection-form"
                    component={CollectionForm}
                />
                <Route exact path="/credits" component={Credits} />
                <Route exact path="/pricing" component={Pricing} />
                <Route exact path="/profile" component={Profile} />
                <Route
                    path="/subscriptions/checkout-success"
                    component={SubscriptionSuccess}
                />
                <Route
                    path="/credits/order-success"
                    component={CreditsOrderSuccess}
                />
                <Route exact path="/products/:id" component={Product} />
                <Route exact path="/art/:id" component={Art} />
                <Route path="/products/:id/edit" component={UploadForm} />
                <Route exact path="/collections/:id" component={Collection} />
                <Route
                    exact
                    path="/collections/:id/edit/"
                    component={CollectionForm}
                />
                <Route path="*" component={NotFoundPage} />

                {/* <Route exact path="/profile" component={Profile} /> */}
            </Switch>
        </Router>
    );
}

export default App;
