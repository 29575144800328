// React
import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";

// Local
import { COLORS } from "../../Constants";
import ActionButton from "../atoms/ActionButton";
import enefete from "../../api/enefete";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    sendCode: {
        color: "#6774b7",
        cursor: "pointer",
        fontWeight: "bold",
        "&:hover": {
            color: "#414c88",
        },
    },
}));

const SellProductModal = ({ open, handleClose, product, onSuccess }) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [price, setPrice] = React.useState(0);
    const [priceError, setPriceError] = React.useState("");

    const updatePrice = (event) => {
        setPrice(event.target.value);
        setPriceError(false);
    };

    const sendSell = async () => {
        try {
            const response = await enefete.patch(
                `/v1/products/${product.id}/set-for-sale/`,
                {
                    for_sale_price: price,
                    for_sale_status: "Pending",
                },
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status === 200) {
                onSuccess();
                handleClose();
            }
        } catch (e) {
            console.log(e);
            alert("We can't process this right now");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        sendSell();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Grid container>
                    <Grid item xs="8">
                        <Typography variant="h4" component="h4">
                            Set for sale
                        </Typography>
                    </Grid>
                    <Grid item xs="4">
                        <CloseIcon
                            className="float-right"
                            onClick={handleClose}
                            style={{
                                cursor: "pointer",
                                color: COLORS.enefete_blue,
                                fontSize: "2.8em",
                            }}
                        />
                    </Grid>
                </Grid>
                <Box m={2} />
                <Grid container>
                    <Grid item xs="12">
                        <Alert severity="info">
                            We will publish your NFT in some marketplaces for
                            you. This means it will be available for purchase at
                            your set price. This has no cost for you 🥳.
                        </Alert>
                    </Grid>
                </Grid>
                <Box m={3} />
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography display="inline">
                                Set the price for{" "}
                                <Typography
                                    color="textSecondary"
                                    display="inline"
                                >
                                    {" "}
                                    ({product.name}){" "}
                                </Typography>
                                in USD.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box m={3} />
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                error={Boolean(priceError)}
                                variant="outlined"
                                label="Price"
                                type="number"
                                placeholder="Price in USD"
                                name="property_name"
                                helperText={priceError ? priceError : ""}
                                fullWidth
                                required
                                value={price}
                                onChange={updatePrice}
                            />
                        </Grid>
                    </Grid>
                    <Box m={2} />
                    <Grid container>
                        <Grid item xs={12}>
                            <ActionButton fullWidth type="submit">
                                Send
                            </ActionButton>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Modal>
    );
};

export default SellProductModal;
