// React
import React from "react";
import { useHistory, Link } from "react-router-dom";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// Material UI
import Alert from "@material-ui/lab/Alert";
import { TextField, Typography, Grid, Box } from "@material-ui/core";

// Third Party
import "../assets/css/phone-input.css";
import PhoneInput from "react-phone-number-input";
import { format } from "date-fns";

// Core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// Local
import ActionButton from "../components/atoms/ActionButton";
import useGetUserProfile from "../hooks/useGetUserProfile";
import useGetUserSubscription from "../hooks/useGetUserSubscription";
import Loading from "../components/sections/Loading";
import enefete from "api/enefete";

const Profile = () => {
    const history = useHistory();
    const [profile, getProfile] = useGetUserProfile();
    const [phoneNumber, setPhoneNumber] = React.useState();
    const [code, setCode] = React.useState();
    const [codeError, setCodeError] = React.useState();
    const [messageSent, setMessageSent] = React.useState();
    const [phoneError, setPhoneError] = React.useState();
    const [
        showPhoneValidationAlert,
        setShowPhoneValidationAlert,
    ] = React.useState();
    const [userSubscription, getUserSubscription] = useGetUserSubscription();

    const validateTokenExists = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            history.push("/sign-in");
        }
    };

    const sendPhoneMessage = async (phoneNumber) => {
        try {
            const response = await enefete.post(
                "/v1/users/phone-add/",
                {
                    phone: phoneNumber,
                },
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status === 200) {
                setMessageSent(true);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
            setPhoneError("Please insert a valid phone");
        }
    };

    const sendVerify = async (code) => {
        try {
            const response = await enefete.post(
                "/v1/users/phone-verify/",
                {
                    code,
                },
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );

            if (response.status === 200) {
                getProfile();
                setShowPhoneValidationAlert(true);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
            setPhoneError("We can't process this right now");
        }
    };

    const createPortalSession = async () => {
        try {
            const response = await enefete.get(
                "/v1/subscriptions/create-portal-session/",
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status === 200) {
                console.log(response);
                window.location.href = response.data.url;
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
            alert("We can't process this right now");
        }
    };

    const updatePhoneNumber = (phoneNumber) => {
        setPhoneNumber(phoneNumber);
        setPhoneError(null);
    };

    const updateCode = (event) => {
        setCode(event.target.value);
        setCodeError("Invalid code");
    };

    React.useEffect(() => {
        validateTokenExists();
        getProfile();
        getUserSubscription();
    }, []);

    if (!profile) {
        return <Loading />;
    }

    const renderPhoneForm = () => {
        if (profile.is_phone_verified) return "";
        if (!messageSent)
            return (
                <Row className="justify-content-center">
                    <Col sm="10">
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={updatePhoneNumber}
                        />
                    </Col>
                    <Col sm="2">
                        <con
                            className="float-right"
                            onClick={(e) => {
                                e.preventDefault();
                                sendPhoneMessage(phoneNumber);
                            }}
                            size="small"
                            style={{
                                height: "100%",
                            }}
                        >
                            Send
                        </con>
                    </Col>
                </Row>
            );

        return (
            <Row>
                <Col sm="10">
                    <TextField
                        id="outlined-basic"
                        label="Code"
                        variant="outlined"
                        fullWidth
                        onChange={updateCode}
                        value={code}
                    />
                </Col>

                <con
                    className="float-right"
                    onClick={(e) => {
                        e.preventDefault();
                        sendVerify(code);
                    }}
                >
                    Verify
                </con>
            </Row>
        );
    };

    const renderSubscriptionSection = () => {
        if (userSubscription.length < 1)
            return (
                <div className="mt-1 py-5 border-top text-center">
                    <Grid container>
                        <Typography variant="h5">Subscription</Typography>
                    </Grid>
                    <Box m={1} />
                    <Grid container>
                        <Typography variant="h6" color="textSecondary">
                            You don't have an active subscription.{" "}
                            <Link to="/pricing">
                                Click here to see the plans.
                            </Link>
                        </Typography>
                    </Grid>
                </div>
            );

        if (userSubscription.length >= 1) {
            const subscription = userSubscription[0];
            const renewalDate = format(
                new Date(subscription.current_period_end),
                "MMMM d"
            );

            let statusMessage = "Set for renewal at " + renewalDate;
            if (subscription.cancel_at_period_end) {
                const cancelDate = format(
                    new Date(subscription.cancel_at),
                    "MMMM d"
                );
                statusMessage = `You subscription will end at ${cancelDate}.`;
            }

            return (
                <div className="mt-1 py-5 border-top text-center">
                    <Grid container>
                        <Typography variant="h5">Subscription</Typography>
                    </Grid>
                    <Box m={1} />
                    <Grid container>
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            display="inline"
                        >
                            You're subscribed to the
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                display="inline"
                                style={{ fontWeight: "bold" }}
                            >
                                <bold>
                                    {" " + subscription.plan.name + "​​ "}
                                </bold>
                            </Typography>
                            plan.
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography variant="h6" color="textSecondary">
                            Status: {subscription.status}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography variant="h6" color="textSecondary">
                            {statusMessage}
                        </Typography>
                    </Grid>
                    <Box m={1} />
                    <Grid container>
                        <ActionButton onClick={createPortalSession}>
                            Manage your subscription
                        </ActionButton>
                    </Grid>
                </div>
            );
        }
    };

    return (
        <>
            <DemoNavbar history={history} />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                <Row
                                    className="justify-content-center"
                                    style={{ marginTop: 20 }}
                                >
                                    <Col sm="12">
                                        {showPhoneValidationAlert && (
                                            <Alert>
                                                Your phone is validated. You can
                                                start minting your NFTS!
                                            </Alert>
                                        )}

                                        {!profile.is_phone_verified && (
                                            <Alert severity="info">
                                                To enable the Minting of tokens
                                                you need to add a phone number
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <div className="text-center mt-5">
                                    <h3>{profile.username}</h3>
                                </div>
                                <div className="mt-5 py-5 border-top text-center">
                                    <Grid container>
                                        <Typography variant="h5">
                                            Details
                                        </Typography>
                                    </Grid>
                                    <Box m={1} />
                                    <Grid container>
                                        {profile.is_phone_verified && (
                                            <Typography
                                                variant="h6"
                                                color="textSecondary"
                                            >
                                                You have a verified phone that
                                                ends in {profile.phone}
                                            </Typography>
                                        )}
                                        <Typography color="error" variant="h6">
                                            {phoneError}
                                        </Typography>
                                        <Typography color="error" variant="h6">
                                            {codeError}
                                        </Typography>
                                        <Grid item xs={12}>
                                            {renderPhoneForm()}
                                        </Grid>
                                    </Grid>
                                </div>
                                {renderSubscriptionSection()}
                            </div>
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </>
    );
};

export default Profile;
