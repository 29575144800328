// React
import React from "react";
import { Link } from "react-router-dom";

// Reactstrap
import { Row, Col } from "reactstrap";

// Material UI
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";

// Local
import enefete from "api/enefete";
import MintButton from "../atoms/MintButton";
import CancelButton from "../atoms/CancelButton";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    sendCode: {
        color: "#6774b7",
        cursor: "pointer",
        fontWeight: "bold",
        "&:hover": {
            color: "#414c88",
        },
    },
}));

const MintModal = ({ product_id, profile, open, handleClose, onSuccess }) => {
    const [modalStyle] = React.useState(getModalStyle);
    const [code, setCode] = React.useState();
    const [codeError, setCodeError] = React.useState();
    const [type, setType] = React.useState();
    const [typeError, setTypeError] = React.useState();
    const classes = useStyles();

    const updateCode = (event) => {
        setCode(event.target.value);
        setCodeError(false);
    };

    const updateType = (event) => {
        setType(event.target.value);
        setTypeError(false);
    };

    const sendCode = async () => {
        try {
            const response = await enefete.get("v1/users/phone-auth", {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });

            if (response.status === 200) {
                alert("Message sent");
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
            alert("Can't proccess this right now");
        }
    };

    const sendMintRequest = async () => {
        try {
            const response = await enefete.post(
                `v1/products/${product_id}/mint/`,
                {
                    code,
                    type,
                },
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status === 200) {
                onSuccess();
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
            setCodeError("Invalid code");
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!type) {
            setTypeError(true);
        }

        sendMintRequest();
    };

    const renderUserTokensMessage = () => {
        if (profile.credits <= 0)
            return (
                <p>
                    You have don't have any credits remaining.{" "}
                    <Link to="/credits">Get more.</Link>
                </p>
            );

        return (
            <p>
                You have{" "}
                <strong>
                    {profile.credits} credit
                    {profile.credits > 1 && "s "}
                </strong>{" "}
                remaining. <Link to="/credits">Get more.</Link>
            </p>
        );
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div style={modalStyle} className={classes.paper}>
                <Row>
                    <Col sm="8">
                        <h2 id="simple-modal-title">Mint your NFT</h2>
                    </Col>
                    <Col sm="2">
                        <CancelButton onClick={handleClose}>
                            Cancel
                        </CancelButton>
                    </Col>
                </Row>
                <Box m={2}></Box>
                <Row>
                    <Col sm="12">
                        <Alert severity="warning">
                            If you send the request for your minting, you won't
                            be able to edit this anymore.
                        </Alert>
                    </Col>
                </Row>
                <Box m={3}></Box>
                <Row>
                    <Col sm="12">
                        <form onSubmit={handleSubmit}>
                            {renderUserTokensMessage()}
                            <FormControl
                                component="fieldset"
                                error={typeError}
                                className={classes.formControl}
                            >
                                <FormLabel component="legend">
                                    Select type
                                </FormLabel>
                                <RadioGroup
                                    row
                                    required
                                    aria-label="position"
                                    name="position"
                                    defaultValue="top"
                                    style={{
                                        marginLeft: -15,
                                    }}
                                    value={type}
                                    onChange={updateType}
                                >
                                    <FormControlLabel
                                        value="Normal"
                                        control={<Radio color="primary" />}
                                        label="Normal (1 credit)"
                                        labelPlacement="top"
                                        disabled={
                                            profile.credits >= 1 ? false : true
                                        }
                                    />
                                    <FormControlLabel
                                        disabled={
                                            profile.credits >= 2 ? false : true
                                        }
                                        value="Express"
                                        control={<Radio color="primary" />}
                                        label="Express (2 credits)"
                                        labelPlacement="top"
                                    />
                                </RadioGroup>
                                <FormHelperText>
                                    Normal (24 hours) / Express (1 hour)
                                </FormHelperText>
                            </FormControl>
                            <Box m={2} />
                            <p>
                                To validate it is you, we'll send you a code to
                                your phone. Please allow a couple of seconds to
                                receive it.{" "}
                                {profile.credits > 0 && (
                                    <span
                                        className={classes.sendCode}
                                        onClick={() => {
                                            sendCode();
                                        }}
                                    >
                                        Click here to send it.
                                    </span>
                                )}
                            </p>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="code"
                                label="Code"
                                type="number"
                                name="Code"
                                value={code}
                                onChange={updateCode}
                                disabled={profile.credits < 0}
                                error={codeError}
                                helperText={"Invalid code" ? codeError : ""}
                            />
                            <Box m={2} />

                            <MintButton
                                type="submit"
                                fullWidth
                                disabled={profile.credits < 0}
                            >
                                Mint
                            </MintButton>
                        </form>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default MintModal;
