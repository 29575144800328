// React
import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

// Material UI

import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";

// Core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";

// Reactstrap
import { Button, Card, Container } from "reactstrap";

// Third Party
import { useDropzone } from "react-dropzone";

// Local
import { COLORS } from "../Constants";
import ActionButton from "../components/atoms/ActionButton";
import enefete from "api/enefete";
import useGetProduct from "hooks/useGetProduct";
import SimpleFooter from "./Footers/SimpleFooter";
import useGetUserCollections from "hooks/useGetUserCollections";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
};

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
};

const img = {
    display: "block",
    width: "auto",
    height: "100%",
};

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px 0 20px 0",
    borderWidth: 4,
    borderRadius: 4,
    borderColor: "#c0c0c0",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    height: "20em",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const CREATE = "CREATE";
const EDIT = "EDIT";

const UploadForm = () => {
    const [type, setType] = React.useState(CREATE);
    const params = useParams();
    if (params.id && type !== EDIT) {
        setType(EDIT);
    }

    const [product, getProduct] = useGetProduct();
    const classes = useStyles();
    const history = useHistory();

    const [propertiesList, setPropertiesList] = React.useState([
        { property_name: "", property_value: "", error: "" },
    ]);

    const handlePropertyAdd = () => {
        setPropertiesList([
            ...propertiesList,
            { property_name: "", property_value: "", error: "" },
        ]);
    };

    const handlePropertyChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...propertiesList];
        list[index][name] = value;
        list[index]["error"] = "";
        setPropertiesList(list);
    };

    const handlePropertyRemove = (index) => {
        const list = [...propertiesList];
        list.splice(index, 1);
        setPropertiesList(list);
    };

    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [royalties, setRoyalties] = React.useState(0);
    const [supply, setSupply] = React.useState(1);
    const [collection, setCollection] = React.useState("");
    const [collections, getCollections] = useGetUserCollections([]);

    let query = new URLSearchParams(useLocation().search);
    if (query.get("collection") && collections && !collection) {
        setCollection(query.get("collection"));
    }

    const [files, setFiles] = React.useState([]);
    const [fileError, setFileError] = React.useState("");
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: "image/*",
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            setFileError("");
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} alt={file.name} />
            </div>
        </div>
    ));

    React.useEffect(() => {
        getCollections();
        if (type === EDIT) {
            getProduct(params.id);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [type]);

    React.useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    const uploadForm = async (formData) => {
        try {
            const response = await enefete.post("/v1/products/", formData, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });
            if (response.status === 201) {
                history.push("/");
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    const sendUpdate = async (formData) => {
        try {
            const response = await enefete.post(
                `/v1/products/${params.id}/update/`,
                formData,
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status === 200) {
                history.push(`/products/${params.id}/`);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    const validateProperties = (properties) => {
        let error = false;
        const updatedPropertiesList = [];
        propertiesList.forEach((property) => {
            if (
                property.property_name.trim() &&
                !property.property_value.trim()
            ) {
                property.error = "Invalid input";
                error = true;
            }
            updatedPropertiesList.push(property);
        });

        return [error, propertiesList];
    };

    const cleanProperties = (properties) => {
        // Removing empty properties
        const cleanedPropertiesList = [];
        properties.forEach((property) => {
            if (property.property_name.trim()) {
                cleanedPropertiesList.push({
                    name: property.property_name,
                    value: property.property_value,
                });
            }
        });
        return cleanedPropertiesList;
    };

    const handleCreateSubmit = (event) => {
        event.preventDefault();
        let formError = false;

        if (!files.length) {
            formError = true;
            setFileError("Please upload a valid image");
        }

        const [propertiesError, updatedPropertiesList] = validateProperties(
            propertiesList
        );
        if (propertiesError) setPropertiesList(updatedPropertiesList);

        if (formError) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        const formData = new FormData();
        formData.append("image", files[0]); // appending file
        formData.append(
            "properties",
            JSON.stringify(cleanProperties(propertiesList))
        );
        formData.append("name", name);
        formData.append("description", description);
        formData.append("royalties", royalties);
        formData.append("supply", supply);
        formData.append("collection_id", collection);
        uploadForm(formData);
    };

    const handleUpdateSubmit = (event) => {
        event.preventDefault();
        let formError = false;

        const [propertiesError, updatedPropertiesList] = validateProperties(
            propertiesList
        );
        if (propertiesError) setPropertiesList(updatedPropertiesList);

        if (formError) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }

        const formData = new FormData();
        if (files.length) {
            formData.append("image", files[0]); // appending file
        }

        formData.append(
            "properties",
            JSON.stringify(cleanProperties(propertiesList))
        );
        formData.append("name", name);
        formData.append("description", description);
        formData.append("royalties", royalties);
        formData.append("supply", supply);
        formData.append("collection_id", collection);
        sendUpdate(formData);
    };

    const handleSubmit =
        type === CREATE ? handleCreateSubmit : handleUpdateSubmit;

    const updateName = (event) => {
        setName(event.target.value);
    };

    const updateDescription = (event) => {
        setDescription(event.target.value);
    };

    const updateRoyalties = (event) => {
        setRoyalties(event.target.value);
    };

    const updateSupply = (event) => {
        setSupply(event.target.value);
    };

    if (product && !name) {
        setName(product.name);
        setDescription(product.description);
        setRoyalties(product.royalties);
        setSupply(product.supply);
        if (product.collection) {
            setCollection(product.collection.id);
        }

        const renamedPropertiesList = [];
        product.properties.forEach((property) => {
            renamedPropertiesList.push({
                property_name: property.name,
                property_value: property.value,
            });
        });

        if (product.properties.length > 0) {
            setPropertiesList(renamedPropertiesList);
        }
    }

    const renderTitle = () => {
        if (type === EDIT) return `Edit`;
        return "Upload your art";
    };

    const handleCollectionChange = (event) => {
        setCollection(event.target.value);
    };

    return (
        <>
            <DemoNavbar history={history} />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                <Container component="main" maxWidth="xs">
                                    <CssBaseline />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <ArrowBackIcon
                                                onClick={() => {
                                                    history.goBack();
                                                }}
                                                style={{
                                                    color: COLORS.enefete_blue,
                                                    fontSize: "2.5em",
                                                    marginTop: 20,
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className={classes.paper}>
                                        {/* <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar> */}

                                        <Typography component="h1" variant="h5">
                                            {renderTitle()}
                                        </Typography>
                                        {/* {error && (
                    <Typography component="h1" variant="h6" color="error">
                        Invalid credentials.
                    </Typography>
                )} */}
                                        <form
                                            className={classes.form}
                                            onSubmit={handleSubmit}
                                        >
                                            {fileError && (
                                                <Typography
                                                    component="h1"
                                                    variant="h6"
                                                    color="error"
                                                >
                                                    {fileError}
                                                </Typography>
                                            )}
                                            {type === EDIT && (
                                                <>
                                                    <Typography
                                                        component="h3"
                                                        variant="h5"
                                                    >
                                                        Current image
                                                    </Typography>
                                                    <img
                                                        srcSet={product?.image}
                                                        alt={product?.name}
                                                        height={100}
                                                        width={100}
                                                    />
                                                    <Box mt={2} />
                                                </>
                                            )}
                                            <section>
                                                <div
                                                    {...getRootProps({ style })}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <p>
                                                        Drag 'n' drop some files
                                                        here, or click to select
                                                        files
                                                    </p>
                                                </div>
                                                <aside style={thumbsContainer}>
                                                    {thumbs}
                                                </aside>
                                            </section>
                                            <TextField
                                                id="outlined-select-currency"
                                                select
                                                label="Collection"
                                                value={collection}
                                                onChange={
                                                    handleCollectionChange
                                                }
                                                variant="outlined"
                                                fullWidth
                                            >
                                                {collections.map(
                                                    (collection) => (
                                                        <MenuItem
                                                            key={
                                                                collection.name
                                                            }
                                                            value={
                                                                collection.id
                                                            }
                                                        >
                                                            {collection.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </TextField>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="name"
                                                label="Name"
                                                name="name"
                                                type="text"
                                                value={name}
                                                onChange={updateName}
                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                id="description"
                                                label="Description"
                                                name="description"
                                                type="text"
                                                multiline
                                                rows={4}
                                                value={description}
                                                onChange={updateDescription}
                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="royalties"
                                                label="Royalties"
                                                name="royalties"
                                                type="number"
                                                value={royalties}
                                                onChange={updateRoyalties}
                                                helperText="Suggestion: 10%, 15%, 20%"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            %
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="supply"
                                                label="Supply"
                                                name="supply"
                                                type="number"
                                                value={supply}
                                                onChange={updateSupply}
                                                helperText="The number of copies that will be minted"
                                            />
                                            <Box mt={2} />
                                            <Typography
                                                component="h3"
                                                variant="h5"
                                            >
                                                Properties
                                            </Typography>
                                            <Box mt={1} />
                                            <Grid container spacing={2}>
                                                <Grid item xs={5}>
                                                    <Typography
                                                        component="p"
                                                        color="textSecondary"
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        Type
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography
                                                        component="p"
                                                        color="textSecondary"
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                    >
                                                        Value
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                {propertiesList.map((x, i) => {
                                                    return (
                                                        <>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    placeholder="E.g: Size"
                                                                    name="property_name"
                                                                    value={
                                                                        x.property_name
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handlePropertyChange(
                                                                            e,
                                                                            i
                                                                        )
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    placeholder="L"
                                                                    name="property_value"
                                                                    autoComplete="lname"
                                                                    value={
                                                                        x.property_value
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handlePropertyChange(
                                                                            e,
                                                                            i
                                                                        )
                                                                    }
                                                                    {...(x.error
                                                                        ? {
                                                                              error: true,
                                                                              helperText:
                                                                                  "Invalid input",
                                                                          }
                                                                        : {})}
                                                                />
                                                            </Grid>
                                                            {propertiesList.length !==
                                                                1 && (
                                                                <Grid
                                                                    item
                                                                    xs={2}
                                                                >
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        color="secondary"
                                                                        onClick={() =>
                                                                            handlePropertyRemove(
                                                                                i
                                                                            )
                                                                        }
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                                <Grid item xs={6}>
                                                    <Button
                                                        className="btn-1 btn-neutral ml-1"
                                                        color="default"
                                                        type="button"
                                                        onClick={
                                                            handlePropertyAdd
                                                        }
                                                    >
                                                        Add another
                                                    </Button>
                                                </Grid>
                                            </Grid>

                                            {/* <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    /> */}
                                            <Box mb={2} />

                                            <ActionButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={classes.submit}
                                            >
                                                Upload
                                            </ActionButton>
                                            {/* <Grid container>
                        <Grid item xs>
                            <Link href="/password-reset" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/sign-up" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                                        </form>
                                    </div>
                                    {/* <Box mt={8}>
                <Copyright />
            </Box> */}
                                </Container>
                            </div>
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </>
    );
};

export default UploadForm;
