// React
import React from "react";
import { useHistory, Link } from "react-router-dom";

// Material UI
import Grid from "@material-ui/core/Grid";
import { Typography, Box } from "@material-ui/core";
import Brightness1Icon from "@material-ui/icons/Brightness1";

// Reactstrap
import {
    Button,
    Card,
    Col,
    CardImg,
    CardTitle,
    CardSubtitle,
    CardBody,
    ButtonGroup,
} from "reactstrap";

// Local
import { COLORS } from "../../Constants";

const ProductCard = ({ product, isOwned }) => {
    const history = useHistory();
    const renderCollection = () => {
        if (!product.collection) return "";
        const collectionLink = `/collections/${product.collection.id}`;
        return (
            <CardSubtitle>
                <Grid justify="center" alignItems="center" direction="row">
                    <img
                        alt={product.name}
                        src={product.collection.image}
                        style={{
                            heigh: 20,
                            width: 20,
                            borderRadius: 50,
                        }}
                    />
                    <Typography display="inline" color="textSecondary">
                        {" "}
                        <Link to={collectionLink}>
                            {product.collection.name}
                        </Link>
                    </Typography>
                </Grid>
            </CardSubtitle>
        );
    };
    return (
        <Col md="4" key={product.id}>
            <Card className="mb-4 box-shadow">
                <CardImg
                    top
                    width="100%"
                    src={product.image}
                    alt={product.name}
                />
                <CardBody>
                    <CardTitle tag="h5">{product.name}</CardTitle>
                    {renderCollection()}
                    <Box m={1} />
                    {product.nft_url !== null && (
                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                            <Brightness1Icon
                                style={{
                                    fontSize: "1em",
                                    color: COLORS.enefete_yellow,
                                }}
                            />{" "}
                            Minted
                        </CardSubtitle>
                    )}
                    {product.for_sale_status === "For sale" && (
                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                            <Brightness1Icon
                                style={{
                                    fontSize: "1em",
                                    color: COLORS.enefete_green,
                                }}
                            />{" "}
                            For sale
                        </CardSubtitle>
                    )}

                    <div className="align-items-center">
                        <ButtonGroup>
                            <Button
                                outline
                                size="sm"
                                onClick={() => {
                                    history.push(`/art/${product.id}/`);
                                }}
                            >
                                View
                            </Button>
                            {isOwned && (
                                <Button
                                    outline
                                    size="sm"
                                    onClick={() => {
                                        history.push(
                                            `/products/${product.id}/`
                                        );
                                    }}
                                >
                                    Manage
                                </Button>
                            )}

                            {/* <Button
                                outline
                                color="secondary"
                                size="sm"
                                onClick={() => {
                                    history.push(
                                        `/products/${product.id}/edit`
                                    );
                                }}
                            >
                                Edit
                            </Button> */}
                        </ButtonGroup>
                        {/* <small className="text-muted">
                                                    {item.name}
                                                </small> */}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default ProductCard;
