// React
import React from "react";

// Local
import enefete from "../api/enefete";

export default function useGetCollection() {
    const [collection, setCollection] = React.useState();

    const getCollection = async (id) => {
        try {
            const response = await enefete.get(`/v1/collections/${id}`, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });
            if (response.status === 200) {
                setCollection(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    return [collection, getCollection];
}
