// React
import React from "react";

// Local
import enefete from "../api/enefete";

export default function useGetUserSubscription() {
    const [userSubscription, setUserSubscription] = React.useState([]);

    const getUserSubscription = async () => {
        try {
            const response = await enefete.get("v1/subscriptions/", {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });

            if (response.status === 200) {
                setUserSubscription(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    return [userSubscription, getUserSubscription];
}
