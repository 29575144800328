// React
import React from "react";
import { useHistory, useParams } from "react-router-dom";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ListItemText from "@material-ui/core/ListItemText";

// Local
import useGetCollection from "../hooks/useGetCollection";
import useGetCollectionProducts from "../hooks/useGetCollectionProducts";

import ProductCard from "../components/atoms/ProductCard";
import GoBackArrow from "../components/atoms/GoBackArrow";
import ActionButton from "../components/atoms/ActionButton";

import Loading from "../components/sections/Loading";
import CollectionDeleteModal from "../components/sections/CollectionDeleteModal";

import { COLORS } from "../Constants";

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        "&:focus": {
            backgroundColor: COLORS.enefete_blue,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const Collection = () => {
    const history = useHistory();
    const params = useParams();
    const [collection, getCollection] = useGetCollection();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [
        openCollectionDeleteModal,
        setOpenCollectionDeleteModal,
    ] = React.useState();

    const handleOpenProductDeleteModal = () => {
        setOpenCollectionDeleteModal(true);
    };

    const handleCloseProductDeleteModal = () => {
        setOpenCollectionDeleteModal(false);
    };

    const onCollectionDeleteModalSuccess = () => {
        history.push("/");
    };

    const [
        collectionProducts,
        getCollectionProducts,
    ] = useGetCollectionProducts();

    React.useEffect(() => {
        getCollection(params.id);
        getCollectionProducts(params.id);
    }, []);

    const renderProducts = (collectionProducts) => {
        return (
            <div className="album py-5">
                <Container>
                    <Row>
                        {collectionProducts.map((item) => (
                            <ProductCard product={item} />
                        ))}
                    </Row>
                </Container>
            </div>
        );
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    if (!collection) {
        return <Loading />;
    }

    return (
        <>
            <CollectionDeleteModal
                collection={collection}
                open={openCollectionDeleteModal}
                handleClose={handleCloseProductDeleteModal}
                onSuccess={onCollectionDeleteModalSuccess}
            />
            <DemoNavbar history={history} />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                <Row style={{ marginTop: 20 }}>
                                    <Col xs="6">
                                        <GoBackArrow />
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        <div className="card-profile-image">
                                            <a
                                                href="#pablo"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <img
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={collection.image}
                                                    height={120}
                                                    width={120}
                                                />
                                            </a>
                                        </div>
                                    </Col>
                                    <Col
                                        className="order-lg-3 text-lg-right align-self-lg-center"
                                        lg="4"
                                    >
                                        <div className="card-profile-actions py-4 mt-lg-0">
                                            {/* <ActionButton
                                                className="float-right"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    history.push("upload-form");
                                                }}
                                                size="sm"
                                            >
                                                Add
                                            </ActionButton> */}
                                        </div>
                                    </Col>
                                    <Col className="order-lg-1" lg="4">
                                        <div className="card-profile-stats d-flex justify-content-center">
                                            <div>
                                                <span className="heading">
                                                    {collectionProducts.length}
                                                </span>
                                                <span className="description">
                                                    Item
                                                    {collectionProducts.length ===
                                                    1
                                                        ? ""
                                                        : "s"}
                                                </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center mt-2">
                                    <h3>{collection.name}</h3>
                                    <h5>{collection.description}</h5>
                                </div>
                                <Grid
                                    container
                                    justify="center"
                                    alignItems="center"
                                >
                                    <ActionButton
                                        className="float-right"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            history.push(
                                                `/upload-form?collection=${collection.id}`
                                            );
                                        }}
                                        size="sm"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    >
                                        + Product
                                    </ActionButton>
                                    <StyledMenu
                                        id="customized-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleMenuClose}
                                    >
                                        <StyledMenuItem
                                            onClick={() => {
                                                history.push(
                                                    `/collections/${params.id}/edit/`
                                                );
                                            }}
                                        >
                                            <ListItemIcon>
                                                <EditIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Edit" />
                                        </StyledMenuItem>
                                        <StyledMenuItem
                                            onClick={() => {
                                                handleMenuClose();
                                                handleOpenProductDeleteModal();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <DeleteIcon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary="Delete" />
                                        </StyledMenuItem>
                                    </StyledMenu>
                                    <ActionButton
                                        className="float-right"
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleMenuClick}
                                        style={{ marginLeft: 5 }}
                                    >
                                        &#9660; Menu
                                    </ActionButton>
                                </Grid>
                                <div className="mt-5 py-5 border-top text-center">
                                    {collectionProducts.length === 0 && (
                                        <h3>This collection has no items.</h3>
                                    )}
                                    {collectionProducts.length > 0 &&
                                        renderProducts(collectionProducts)}
                                </div>
                            </div>
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </>
    );
};

export default Collection;
