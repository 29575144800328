// React
import React from "react";

// Local
import enefete from "../api/enefete";

export default function useGetMintRequest() {
    const [mintRequest, setMintRequest] = React.useState();

    const getMintRequest = async (id) => {
        try {
            const response = await enefete.get(`/v1/products/${id}/mint/`, {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });
            if (response.status === 200) {
                setMintRequest(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    return [mintRequest, getMintRequest];
}
