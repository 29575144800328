// React
import React from "react";

// reactstrap components
import { Card, Container } from "reactstrap";

// Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";

// Core Components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const Loading = () => {
    return (
        <React.Fragment>
            <CssBaseline />
            <DemoNavbar />
            <main className="profile-page">
                <section className="section-profile-cover section-shaped my-0"></section>
                <section className="section">
                    <Container>
                        <Card className="card-profile shadow mt--300">
                            <div className="px-4">
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        marginTop: 30,
                                        marginBottom: 30,
                                    }}
                                >
                                    <CircularProgress align="center" />
                                </div>
                            </div>
                        </Card>
                    </Container>
                </section>
            </main>
            <SimpleFooter />
        </React.Fragment>
    );
};

export default Loading;
