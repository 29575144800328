// React
import React from "react";

// Local
import enefete from "../api/enefete";

export default function useGetUserProducts() {
    const [userProducts, setUserProducts] = React.useState([]);

    const getUserProducts = async () => {
        try {
            const response = await enefete.get("/v1/products/", {
                headers: {
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });

            if (response.status === 200) {
                setUserProducts(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    return [userProducts, getUserProducts];
}
