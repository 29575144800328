// React
import React from "react";
import { useHistory } from "react-router-dom";

// Local
import enefete from "../api/enefete";

const SignOut = () => {
    const history = useHistory();

    const validateTokenExists = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            history.push("/sign-in");
        }
    };

    const signOut = async () => {
        try {
            const response = await enefete.post(
                "/v1/auth/token/logout/",
                {},
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status === 204) {
                localStorage.removeItem("token");
                history.push("/sign-in");
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    React.useEffect(() => {
        validateTokenExists();
        signOut();
    }, []);
    return "";
};

export default SignOut;
