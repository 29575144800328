// React
import React from "react";

// Local
import enefete from "../api/enefete";

export default function useGetUserProfile() {
    const [profile, setProfile] = React.useState();

    const getProfile = async () => {
        try {
            const response = await enefete.get("v1/users/me/", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Token " + localStorage.getItem("token"),
                },
            });

            if (response.status === 200) {
                setProfile(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    return [profile, getProfile];
}
