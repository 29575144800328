// React
import React from "react";
import { useHistory } from "react-router-dom";

// Material UI
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

// Local
import ActionButton from "../components/atoms/ActionButton";
import enefete from "../api/enefete";

// function Copyright() {
//     return (
//         <Typography variant="body2" color="textSecondary" align="center">
//             {"Copyright © "}
//             <Link color="inherit" href="https://material-ui.com/">
//                 Your Website
//             </Link>{" "}
//             {new Date().getFullYear()}
//             {"."}
//         </Typography>
//     );
// }

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignUp() {
    const classes = useStyles();
    const history = useHistory();
    const [errors, setErrors] = React.useState({
        username: "",
        email: "",
        password: "",
    });
    const [username, setUsername] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const updateUsername = (event) => {
        setUsername(event.target.value);
        setErrors({ ...errors, username: "" });
    };

    const updateEmail = (event) => {
        setEmail(event.target.value);
        setErrors({ ...errors, email: "" });
    };

    const updatePassword = (event) => {
        setPassword(event.target.value);
        setErrors({ ...errors, password: "" });
    };

    const signUp = async (username, email, password) => {
        try {
            const response = await enefete.post("v1/auth/users/", {
                username,
                email,
                password,
            });

            if (response.status === 201) {
                history.push("/sign-in");
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
            if (e.response.data?.email) {
                setErrors({ ...errors, email: e.response.data.email });
            }

            if (e.response.data?.username) {
                setErrors({ ...errors, username: e.response.data.username });
            }

            if (e.response.data?.password) {
                setErrors({ ...errors, email: e.response.data.password });
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        signUp(username, email, password);
    };

    const renderErrors = () => {
        const errorMessages = [];
        if (errors.email) {
            errorMessages.push(errors.email);
        }
        if (errors.username) {
            errorMessages.push(errors.username);
        }
        if (errors.password) {
            errorMessages.push(errors.password);
        }

        if (errorMessages.length) {
            return (
                <div>
                    {errorMessages.map((errorMessage) => (
                        <Typography component="h3" variant="h5" color="error">
                            {errorMessage}
                        </Typography>
                    ))}
                </div>
            );
        }
        return "";
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img
                    width={100}
                    src="https://enefete.s3-us-west-2.amazonaws.com/enefete-single.svg"
                    alt="enefete logo"
                />
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                {renderErrors()}
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                name="username"
                                variant="outlined"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                autoFocus
                                value={username}
                                onChange={updateUsername}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                type="email"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={updateEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={updatePassword}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="allowExtraEmails"
                                        color="primary"
                                    />
                                }
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                    </Grid>
                    <ActionButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign Up
                    </ActionButton>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/sign-in" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {/* <Box mt={5}>
                <Copyright />
            </Box> */}
        </Container>
    );
}
