// Material UI
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const CancelButton = withStyles({
    root: {
        boxShadow: "none",
        textTransform: "none",
        fontSize: 16,
        padding: "6px 12px",
        border: "1px solid",
        lineHeight: 1.5,
        backgroundColor: "#dd3d31",
        borderColor: "#dd3d31",
        color: "#fff",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        "&:hover": {
            backgroundColor: "#9f231a",
            borderColor: "#9f231a",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#fcda03",
            borderColor: "#a89102",
        },
        "&:focus": {
            borderColor: "#a89102",
            boxShadow: "none",
        },
    },
})(Button);

export default CancelButton;
