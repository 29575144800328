// React
import React from "react";
import { useHistory } from "react-router-dom";

// Reactstrap
// reactstrap components
import {
    Button,
    Card,
    Col,
    CardImg,
    CardTitle,
    CardBody,
    ButtonGroup,
} from "reactstrap";

const CollectionCard = ({ collection }) => {
    const history = useHistory();
    return (
        <Col md="4" key={collection.id}>
            <Card className="mb-4 box-shadow">
                <CardImg
                    top
                    width="100%"
                    src={collection.image}
                    alt={collection.name}
                />
                <CardBody>
                    <CardTitle>{collection.name}</CardTitle>
                    <div className="align-items-center">
                        <ButtonGroup>
                            <Button
                                outline
                                size="sm"
                                onClick={() => {
                                    history.push(
                                        `/collections/${collection.id}/`
                                    );
                                }}
                            >
                                View Collection
                            </Button>
                            {/* <Button
                                outline
                                color="secondary"
                                size="sm"
                                onClick={() => {
                                    history.push(
                                        `/products/${product.id}/edit`
                                    );
                                }}
                            >
                                Edit
                            </Button> */}
                        </ButtonGroup>
                        {/* <small className="text-muted">
                                                    {item.name}
                                                </small> */}
                    </div>
                </CardBody>
            </Card>
        </Col>
    );
};

export default CollectionCard;
