/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container } from "reactstrap";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    "@global": {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: "none",
        },
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: "wrap",
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[700],
    },
    cardPricing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

const footers = [
    {
        title: "Company",
        description: ["Team", "History", "Contact us", "Locations"],
    },
    {
        title: "Features",
        description: [
            "Cool stuff",
            "Random feature",
            "Team feature",
            "Developer stuff",
            "Another one",
        ],
    },
    {
        title: "Resources",
        description: [
            "Resource",
            "Resource name",
            "Another resource",
            "Final resource",
        ],
    },
    {
        title: "Legal",
        description: ["Privacy policy", "Terms of use"],
    },
];

export default function SimpleFooter() {
    const classes = useStyles();

    return (
        <>
            <footer className=" footer">
                <Container component="footer" className={classes.footer}>
                    <Grid container spacing={4} justify="flex-end">
                        <Grid item xs={6} sm={3}>
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                gutterBottom
                            >
                                Resources
                            </Typography>
                            <ul>
                                <li>
                                    <Link
                                        href="https://www.enefete.com/terms"
                                        variant="subtitle1"
                                        color="textSecondary"
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Terms of service
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        href="https://www.enefete.com/privacy"
                                        variant="subtitle1"
                                        color="textSecondary"
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Privacy Policy
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Box mt={5}></Box>
                </Container>
            </footer>
        </>
    );
}
