// React
import React from "react";

// Local
import enefete from "../api/enefete";

export default function useGetCollectionProducts() {
    const [collectionProducts, setCollectionProducts] = React.useState([]);

    const getCollectionProducts = async (id) => {
        try {
            const response = await enefete.get(
                `/v1/collections/${id}/products`,
                {
                    headers: {
                        Authorization: "Token " + localStorage.getItem("token"),
                    },
                }
            );
            if (response.status === 200) {
                setCollectionProducts(response.data);
            }
        } catch (e) {
            console.log(e);
            console.log(e.response);
        }
    };

    return [collectionProducts, getCollectionProducts];
}
